// stores/peopleModalStore.ts
import { defineStore } from 'pinia'
import { contentTypes } from '~/models'

import type { SearchResultPeopleItem } from '~/types/SearchResultItem'

export const usePeopleModalStore = defineStore('peopleModal', {
  state: () => ({
    items: [] as SearchResultPeopleItem[],
    isGrid: true,
    openAsModal: false,
    layoutIsFullList: false,
    unMutableLayoutIsFullList: false,
    searchValue: '',
    selectedCapability: '',
    selectedLocation: '',
    selectedPosition: '',
    sortBy: 'asc',
    sortOrders: {
      nameDesc: 'desc',
      nameAsc: 'asc'
    },
    positions: [] as string[],
    locations: [] as any[],
    capabilities: [] as string[],
    size: 6,
    totalResults: 0,
    isLoading: false
  }),

  actions: {
    resetStates(){
      this.items = [],
      this.isGrid = true,
      this.openAsModal = false,
      this.layoutIsFullList = false,
      this.unMutableLayoutIsFullList = false,
      this.searchValue = '',
      this.selectedCapability = '',
      this.selectedLocation = '',
      this.selectedPosition = '',
      this.sortBy = 'asc',
      this.sortOrders = {
        nameDesc: 'desc',
        nameAsc: 'asc'
      },
      this.positions = [],
      this.locations = [],
      this.capabilities = [],
      this.size = 6,
      this.totalResults = 0
      this.isLoading = false
    },
    toggleModal() {
      this.openAsModal = !this.openAsModal
    },
    setItems(items: SearchResultPeopleItem[]) {
      this.items = items
    },
    setLayoutIsFullList(isFullList: boolean) {
      if (isFullList) {
        this.size = 20
      } else {
        this.size = 6
      }
      this.layoutIsFullList = isFullList
    },
    setSearchValue(value: string) {
      usePaginationStore().currentPage = 1
      this.searchValue = value
    },

    setPositions(positions: string[]) {
      this.positions = positions
    },
    setLocations(locations: any[]) {
      this.locations = locations
    },
    setCapabilities(capabilities: string[]) {
      this.capabilities = capabilities
    },
    setOpenAsModal(openAsModal: boolean) {
      this.openAsModal = openAsModal
    },
    setIsGrid(isGrid: boolean) {
      this.isGrid = isGrid
    },
    setTotalResults(totalResults: number) {
      this.totalResults = totalResults
    },
    setSelectedCapability(selectedCapability: string) {
      usePaginationStore().currentPage = 1
      this.selectedCapability = selectedCapability
    },
    setSelectedLocation(selectedLocation: string) {
      usePaginationStore().currentPage = 1
      this.selectedLocation = selectedLocation
    },
    setSelectedPosition(selectedPosition: string) {
      usePaginationStore().currentPage = 1
      this.selectedPosition = selectedPosition
    },
    setSortBy(sortBy: string) {
      this.sortBy = sortBy
    },
    setViewAllPreview() {
      this.openAsModal = true
      this.setLayoutIsFullList(true)
    },
    setCloseViewAll() {
      this.clearFilters()
      if (!this.unMutableLayoutIsFullList) {
        this.setLayoutIsFullList(false)
      } else {
        this.size = this.totalResults
      }
      this.isGrid = true
      this.openAsModal = false
    },
    clearFilters() {
      const pageStore = usePageStore()
      const pageType = pageStore.page?.system.type
      if(pageType != contentTypes.capabilities_page.codename){
        this.selectedCapability = ''
      }
      this.selectedLocation = ''
      this.selectedPosition = ''
      this.sortBy = 'asc'
      this.searchValue = ''
    },
    async getLocation(codename:string){
      const locationStore = useLocationStore();
      const allLocations = await locationStore.getLocations();
      const location = allLocations?.find(x=>x.system.codename == codename);
      return location?.elements?.title?.value ?? ''
    },
    getSelectedLocationText() {
      return this.locations.find(l => l.value == this.selectedLocation)?.name;
    },
    setLoading(isLoading: boolean) {
      this.isLoading = isLoading
    }
  }
})
